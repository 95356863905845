import React, { useEffect } from "react";
import { graphql } from "gatsby";
import "../styles/index.scss";
import Image from "gatsby-image";
import Heading from "../components/Heading";
import Button from "../components/Button";
import truck1 from "../images/truck_1.svg";
import truck2 from "../images/truck_2.svg";
import truck3 from "../images/truck_3.svg";
import map from "../images/map.svg";
import road from "../images/road.svg";
import top from "../images/top_images.svg";
import bottom from "../images/bottom_images.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { WindowSizeContext } from "../layouts";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import truck_mobile from "../images/truck_mobile.svg";
import Seo from "../components/seo";

gsap.registerPlugin(ScrollTrigger);

const IndexPage = ({ data }) => {
  const windowSize = React.useContext(WindowSizeContext);
  useEffect(() => {
    const elements = document.querySelectorAll("section");
    elements.forEach((el, index) => {
      ScrollTrigger.matchMedia({
        // small
        "(max-width: 768px)": function () {
          gsap.fromTo(
            el.children,
            { y: "+=50", opacity: 0 },
            {
              y: 0,
              opacity: 1,
              duration: 0.7,
              scrollTrigger: {
                trigger: el,
                start: "top 50%",
                once: true,
              },
            }
          );
        },

        "(min-width: 769px)": function () {
          gsap.fromTo(
            el.children,
            { y: "+=50", opacity: 0 },
            {
              y: 0,
              opacity: 1,
              duration: 0.7,
              scrollTrigger: {
                trigger: el,
                start: "top 50%",
                once: true,
                // markers: true,
              },
            }
          );
        },
      });
    });
  });

  const settings = {
    dots: true,
    arrows: true,

    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <Seo
        title="Zakład Usług Trasnportowych"
        // description={description}
        keywords={[
          "usługi transportowe",
          "zbigniew lidzbiński",
          "transport polska",
        ]}
      />
      <header className="hero">
        <Image
          style={{ height: "100vh" }}
          fluid={data.hero.childImageSharp.fluid}
        />
        <h1>
          {" "}
          <span> zakład usług transportowych </span>{" "}
          <span> Zbigniew Lidzbiński </span>{" "}
        </h1>
      </header>
      <section className="about" id="o-firmie">
        <div className="container ">
          <Heading Tag="h2" text="O firmie" />
          <Heading Tag="h3" text="Poznaj naszą historię" />
          <div className="about-element">
            <div className="img">
              <Image fluid={data.man.childImageSharp.fluid} />
            </div>
            <div className="desc">
              <p>
                Jesteśmy firmą rodzinną, stworzoną w listopadzie 2005 roku.
                <br />
                Założeniu firmy towarzyszyło ryzyko i strach przed
                niepowodzeniem, wygrała jednak wiara w lepsze jutro, pasja do
                dużych aut i rodzinne wsparcie.
              </p>
              <p>
                Będąc na rynku już tyle lat udało nam się pozyskać stałych
                klientów, z którymi podejmujemy owocną współpracę.
              </p>
            </div>
          </div>

          <div className="about-element truck">
            <div className="desc">
              <p>
                Wyróżniamy się solidnością, pracowitością i doświadczeniem.
                <br />
                Ciągle się rozwijamy zakupując coraz to nowsze i różnorodne
                pojazdy. Dostosowujemy się do potrzeb klienta.
              </p>
              <p>
                Przez lata obecności na rynku, wyspecjalizowaliśmy się w
                przewozie wyrobów stalowych. Jednak nasza flota dostosowana jest
                do transportu wszelkiego asortymentu.
              </p>
            </div>
            <div className="img truck">
              <Image fluid={data.truck.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </section>
      <section className="fleet" id="flota">
        <div className="container animation-scroll">
          <Heading Tag="h2" text="Flota" />
          <Heading Tag="h3" text="Zleć nam swój transport" />
          <p className="desc">
            Posiadamy ciągniki siodłowe marki: <b>SCANIA, VOLVO, MAN </b> oraz{" "}
            <b> DAF</b>. Są to pojazdy wysoko wyposażone, we wzorowym stanie
            technicznym, spełniające normy emisji spalin EURO V i EURO VI.
          </p>

          {windowSize < 1024 ? (
            <Slider {...settings} className="overflow-hidden trucks">
              <div className="truck">
                <img src={truck1} alt="cieżarówka" />
                <p className="title">
                  <b>Naczepa kurtynowa (firanka) standard </b>
                </p>
                <ul className="list">
                  <li>wymiary: 13,6 x 2,45 x 2,75 m</li>
                  <li>ładowność: 24 t</li>
                  <li>liczba europalet: 33 </li>
                </ul>
              </div>
              <div className="truck">
                <img src={truck2} alt="cieżarówka" />
                <p className="title">
                  <b>Naczepa ruchoma podłoga </b>
                </p>
                <ul className="list">
                  <li>pojemność: 90 m3</li>
                  <li>ładowność: 23 t</li>
                </ul>
              </div>
              <div className="truck">
                <img src={truck3} alt="cieżarówka" />
                <p className="title">
                  <b>Naczepa wywrotka stalowa </b>
                </p>
                <ul className="list">
                  <li>pojemność: 55 m3</li>
                  <li>ładowność: 23 t</li>
                </ul>
              </div>
            </Slider>
          ) : (
            <div className="trucks">
              <div className="truck">
                <img src={truck1} alt="cieżarówka" />
                <p className="title">
                  <b>Naczepa kurtynowa (firanka) standard </b>
                </p>
                <ul className="list">
                  <li className="li">wymiary: 13,6 x 2,45 x 2,75 m</li>
                  <li className="li">ładowność: 24 t</li>
                  <li className="li">liczba europalet: 33 </li>
                </ul>
              </div>
              <div className="truck">
                <img src={truck2} alt="cieżarówka" />
                <p className="title">
                  <b>Naczepa ruchoma podłoga </b>
                </p>
                <ul className="list">
                  <li className="li">pojemność: 90 m3</li>
                  <li className="li">ładowność: 23 t</li>
                </ul>
              </div>
              <div className="truck">
                <img src={truck3} alt="cieżarówka" />
                <p className="title">
                  <b>Naczepa wywrotka stalowa </b>
                </p>
                <ul className="list">
                  <li className="li">pojemność: 55 m3</li>
                  <li className="li">ładowność: 23 t</li>
                </ul>
              </div>
            </div>
          )}

          <div className=""></div>
          <div className="button-container ">
            <Button href="/galeria" text="zobacz GALERIĘ ZDJĘĆ" />
          </div>
        </div>
      </section>

      <section className="direction">
        <div className="container animation-scroll">
          <Heading Tag="h2" text="Kierunki" />
          <Heading Tag="h3" text="Transport krajowy i międzynarodowy" />
          <p className="desc">
            Specjalizujemy się w transporcie na terenie Polski, Czech, Niemiec,
            Hiszpanii. Przewozimy różnorodny towar, m.in. wyroby stalowe,
            materiały budowlane, wyroby drewniane oraz produkty z branży
            automotive. Posiadamy również zezwolenie na przewóz odpadów na
            terenie Polski oraz Niemiec.
          </p>
          <div className="map">
            <img src={map} alt="mapa" />
          </div>
        </div>
      </section>
      <section className="career" id="kariera">
        <div className="container animation-scroll">
          <div className="title">
            <Heading Tag="h2" text="Kariera" />
            <Heading Tag="h3" text="Dołącz do naszego zespołu!" />
            <div className="colum-button">
              <Button text="sprawdź OFERTY PRACY" href="/kariera" />
            </div>
          </div>
        </div>
        {windowSize < 768 ? (
          <div className="images-mobile">
            <img src={truck_mobile} alt="" />
          </div>
        ) : (
          <div className="images-container animation-scroll">
            <img src={top} alt="" className="top img" />
            <img className="road img" src={road} alt="" />
            <img className="bottom img" src={bottom} alt="" />
          </div>
        )}
      </section>
      <section className="contact ">
        <div className="container animation-scroll">
          <Heading Tag="h2" text="kontakt" />
          <Heading
            Tag="h3"
            text="Skontaktuj się z nami: "
            bold=" 632-447-518"
          />
          <div className="names">
            <div className="name">
              <p>
                <b>ZBIGNIEW LIDŹBIŃSKI</b>
              </p>
              <p>Właściciel</p>
              <p>
                <a href="tel:+48606779192">tel. 606 779 192</a>
              </p>
            </div>
            <div className="name">
              <p>
                <b>BARTOSZ LIDŹBIŃSKI</b>
              </p>
              <p>Specjalista ds. transportu i logistyki </p>
              <p>
                <a href="tel:+48662153519">tel. 662 153 519</a>
              </p>
            </div>
          </div>
          <div className="mail">
            <p>
              <b>KONTAKT MAILOWY</b>
            </p>
            <a href="mailto:biuro@lidzbinski.pl">biuro@lidzbinski.pl</a>
            <a href="mailto:transport@lidzbinski.pl">transport@lidzbinski.pl</a>
          </div>
        </div>
      </section>
      <section className="address " id="kontakt">
        <div className="container animation-scroll">
          <Heading Tag="h2" text="adres" />
          <Heading
            Tag="h3"
            text="Zakład Usług Transportowych Lidźbiński Zbigniew "
          />
          <div className="map">
            <div className="desc">
              <p>Wola Łaszczowa 36</p>
              <p>62-530 Kazimierz Biskupi</p>
              <p className="nip">NIP: 665-140-04-88</p>
            </div>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2441.6623828623487!2d18.230373865798093!3d52.267675629767275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471b3449039ff8c3%3A0xaa73bc6b116916fe!2sLid%C5%BAbi%C5%84ski%20Zbigniew.%20Zak%C5%82ad%20us%C5%82ug%20transportowych!5e0!3m2!1spl!2spl!4v1635777410326!5m2!1spl!2spl"
                width="900"
                height="350"
                frameBorder="0"
                style={{ margin: "0" }}
                allowFullScreen=""
                aria-hidden="false"
                title="mapa Zgigniew Lidźbiński transport"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  {
    hero: file(name: { eq: "hero" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    man: file(name: { eq: "man" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    truck: file(name: { eq: "truck" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default IndexPage;
