import React from "react";
import "../styles/button.scss";
import { Link } from "gatsby";

const Button = (props) => {
  const { text, href } = props;

  return (
    <Link to={href} className="button">
      {text}
    </Link>
  );
};

export default Button;
